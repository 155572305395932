* {
	box-sizing: border-box;
}

body {
	margin: 0;
	font-family: "Inter";
	box-sizing: content-box;
}

code {
	font-family: "Inter", sans-serif;
}

li {
	list-style-type: none;
}

main {
	overflow-x: hidden;
	overflow-y: auto;
}

[class*="root-MuiTextField-root"] fieldset {
	height: auto !important;
}

a {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	direction: rtl;
}

.spinner {
	border: 4px solid #fff;
	border-top: 4px solid #25406d;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.loading-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}
